<template>
    <div class="contact-container animation-container">
        <div class="contact-upper">
            <img :src="contactImg" alt="お問い合わせ画像">
            <div class="contact-text">
                <a class="line-friend-btn" href="https://lin.ee/A5pRGDq" target="_blank"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"></a>
                <p class="contact-bigger-text">どんなことでも気軽にお電話ください。<br>
                私たちは<span>親身で丁寧な対応</span>を心がけます。</p>
            </div>
        </div>
        <router-link to="/contact" class="contact-btn">
            お問い合わせはこちら
        </router-link>
    </div>
</template>
<script>

import contactImg from '../assets/contact-image.png'

export default {
    name: 'the-contact',
    data() {
        return {
            contactImg: contactImg
        }
    },
    components: {

    }
}
</script>