import { createRouter, createWebHistory } from 'vue-router'
import BodyView from '../views/Body.vue'

const routes = [
  {
    path: '/',
    name: 'Body',
    component: BodyView
  },
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/Security.vue')
  },
  {
    path: '/notation',
    name: 'Notation',
    component: () => import('../views/Notation.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Form.vue')
  },
  {
    path: '/thanks',
    name: 'thanks-form',
    component: () => import('../views/ThanksForm.vue')
  },
  {
    path: '/heatmap',
    name: 'HeatMap',
    component: () => import('../views/HeatMap.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
