<template>
    <div class="solution-container">
        <h3 class="underlined-title animation-container">こんなお悩みありませんか？</h3>
        <div class="solutions animation-container">
            <div class="solution">
                <div class="solution-heading">
                    <img :src="solution11">
                    <h4>WEBサイトでの効果を<br>感じられなかった経験がある</h4>
                </div>
                <div class="solution-body">
                    <img :src="solution12">
                    <p>お客様に響くデザイン力や<br class="br-sp">ユーザー体験を<br class="br-pc"><br class="br-tablet">重視し、<br
                            class="br-sp">クリックしたくなる、最後まで<br class="br-sp">見て<br class="br-pc"><br
                            class="br-tablet">もらえるサイトを制作する<br class="br-sp">事に力を入れて<br class="br-pc"><br
                            class="br-tablet">おります。
                        <br class="br-sp">またSPAでのホームページ<br class="br-sp">制作して<br class="br-pc"><br
                            class="br-tablet">いく為、ページ移動の<br class="br-sp">移動時間を０秒にする<br class="br-pc"><br
                            class="br-tablet">ことにより<br class="br-sp">ユーザの使用時のストレスを最大限<br class="br-pc"><br
                            class="br-tablet">まで削減する<br class="br-sp">事を可能とし、ユーザーの滞在時間をUPし結果に<br>繋がりやすくしていきます。
                    </p>
                </div>
            </div>
            <div class="solution">
                <div class="solution-heading">
                    <img :src="solution21">
                    <h4>新しいテクノロジーは<br>予算が高く感じる</h4>
                </div>
                <div class="solution-body">
                    <img :src="solution22">
                    <p>SPAlabではシンプルでわかり<br class="br-sp">やすいプランを<br class="br-pc"><br class="br-tablet">ご用意して<br
                            class="br-sp">おります。
                        <br>
                        制作とコーディングでの相場は<br>20万から100万円程度が世の<br class="br-sp">中の相場ですが、<br class="br-pc"><br
                            class="br-tablet">金額が<br class="br-sp">”高すぎる”と感じない<br class="br-sp">プランの為、<br class="br-pc"><br
                            class="br-tablet">初めてホームページ<br class="br-sp">制作を希望されるお客様は<br class="br-pc"><br
                            class="br-tablet">もちろん、過去に<br class="br-sp">高すぎる料金を支払い後悔したお客様にも<br>安心して制作に依頼のできるサービスも<br
                            class="br-sp">魅力の一つです。
                    </p>
                </div>
            </div>
            <div class="solution">
                <div class="solution-heading">
                    <img :src="solution31">
                    <h4>専門的な事が全くわからない</h4>
                </div>
                <div class="solution-body">
                    <img :src="solution32">
                    <p>ホームページ制作の鍵は、<br class="br-sp">ステークホルダーの<br class="br-pc"><br class="br-tablet">声を<br
                            class="br-sp">聞くこと。状況とニーズを<br class="br-sp">理解し、課題を<br class="br-pc"><br
                            class="br-tablet">解決する提案で、<br class="br-sp">お客様の成功へ導く使命を<br>果たします。<br>
                        SPAlabは新規制作だけではなく<br>リニューアルにも強いです。<br>
                        経験豊富な知識を持ったスタッフとお客様に<br class="br-sp">響くデザイン力や<br class="br-pc"><br class="br-tablet">ユーザー体験を重視して<br
                            class="br-sp">制作していきます。
                    </p>
                </div>
            </div>
        </div>
        <div class="question-solution">
            <div class="question-solution-header underlined-title">
                <p>今後も悩み続けますか?</p>
                <p>それとも革命を起こしますか！?</p>
            </div>
            <div class="question-solution-subheading">
                <p class="question-solution-heading">SPA labを導入すると...</p>
            </div>
            <div class="question-solution-list">
                <div>
                    <p class="question-solution-text">サイト定着率<br><span>215%UP</span></p>
                </div>
                <div>
                    <p class="question-solution-text">アクセス数<br><span>189%UP</span></p>
                </div>
                <div>
                    <p class="question-solution-text">コンバージョン<br><span>88%UP</span></p>
                </div>
            </div>
            <div class="about-us-text animation-container">
                <img :src="icon09">
            </div>
        </div>
    </div>
</template>

<script>
import solution11 from '../assets/solution11.png'
import solution12 from '../assets/solution12.png'
import solution21 from '../assets/solution21.png'
import solution22 from '../assets/solution22.png'
import solution31 from '../assets/solution31.png'
import solution32 from '../assets/solution32.png'
import icon09 from '../assets/icon09.png'

export default {
    name: 'SolutionsComponent',
    data() {
        return {
            solution11: solution11,
            solution12: solution12,
            solution21: solution21,
            solution22: solution22,
            solution31: solution31,
            solution32: solution32,
            icon09: icon09
        }
    }
}
</script>
