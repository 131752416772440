<template>
	<div class="faq-container">
		<h3 class="sub-title">
			よくある質問
		</h3>
		<div class="questions-container">
			<div class="question-box closed" v-for="item in questions" :key="item" @click="handleToggle($event)">
				<div class="question">
					<p>{{ item.question }}</p>
					<div class="open-mark">
						<div class="line-vertical"></div>
						<div class="line-horizontal"></div>
					</div>
				</div>
				<div class="answer">
					<p v-html="item.answer"></p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'question-answer',
	components: {

	},
	methods: {
		handleToggle(e) {
			const target = e.currentTarget;
			target.classList.toggle('closed');
		}
	},
	data() {
		return {
			questions: [
				{
					'question': 'ホームページ制作時間はどのくらいでしょうか？',
					'answer': 'ページ数、内容、規模感によって様々ですが、目安として、シンプルプラン7日、スタンンダードプラン2週間、オリジナルオーダー1~2ヶ月, ECサイトプラン2ヶ月営業日程度で作成可能です。'
				},
				{
					'question': 'ホームページの知識が0でも大丈夫ですか？',
					'answer': '可能です。ご安心ください。初めて利用ホームページを制作されるお客様も数多くおります。'
				},
				{
					'question': '現在契約している所から乗り換える事は可能でしょうか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
				{
					'question': '他社で断られた業種です。依頼する事は可能でしょうか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
				{
					'question': '気に入らない場合はすぐに解約できますか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
				{
					'question': 'ホームページ制作後、修正などの対応もできますか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
				{
					'question': '解約後ホームページは頂けますか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
				{
					'question': '見積もりは費用がかかりますか？',
					'answer': 'お任せください！現在利用中のドメインやメールアドレスを引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>'
				},
			]
		}
	}
}
</script>