import { createStore } from 'vuex';

export default createStore({
  state: {
    scrollDatas: [],
    totalTime: 0,
    averageTime: 0,
  },
  getters: {
    // Your getters here
  },
  mutations: {
    // Your mutations here
    setAnalyticsData(state, payload) {
        state.scrollDatas = payload;
    },
    setTotalTime(state, payload) {
        state.totalTime = payload;
    },
    setAverageTime(state, payload) {
        state.averageTime = payload;
    },
  },
  actions: {
    // Your actions here
  },
  modules: {
    // Your modules here
  },
});