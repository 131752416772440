<template>
    <div class="optional-services-container">
        <h3 class="sub-title animation-container">
            その他オプションサービス
        </h3>
        <div class="optional-services-boxes">
            <div class="optional-services-box animation-container">
                <img :src="optionalPic01" alt="optional-pic01">
                <div class="optional-services-text">
                    <h4>ランディングページ(LP)作成</h4>
                    <p>ホームぺージとは別にアピールしたいサービスや商品がある場合、そのサービスや商品に特化し、お問い合わせや購入などを目的としたページを制作することができます。
                    </p>
                </div>
            </div>
            <div class="optional-services-box animation-container">
                <img :src="optionalPic02" alt="optional-pic02">
                <div class="optional-services-text">
                    <h4>ロゴ・イラスト作成</h4>
                    <p>企業や店舗のブランドイメージを高める、ロゴやイラストを作成することができます。作成したロゴやイラストはチラシや看板など、ホームぺージ以外でもお使いいただくことができます。
                    </p>
                </div>
            </div>
            <div class="optional-services-box animation-container">
                <img :src="optionalPic03" alt="optional-pic03">
                <div class="optional-services-text">
                    <h4>バナー制作</h4>
                    <p>ターゲットオーディエンスに訴求し、クリック率とコンバージョン率を向上させるカスタムバナーをデザインしましょう。ビジュアルと戦略の両面で、ビジネスの成果を最大化します。
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import optionalPic01 from '../assets/optional-pic01.jpg'
import optionalPic02 from '../assets/optional-pic02.jpg'
import optionalPic03 from '../assets/optional-pic03.jpg'

export default {
    name: 'optional-services',
    components: {

    },
    data() {
        return {
            optionalPic01: optionalPic01,
            optionalPic02: optionalPic02,
            optionalPic03: optionalPic03,
        }
    },
}
</script>