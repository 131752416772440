<template>
    <main-visual id="top"></main-visual>
    <solution id="solution"></solution>
    <about-us id="point"></about-us>
    <the-consult id="about-spa"></the-consult>
    <the-reason id="plan"></the-reason>
    <the-flow id="flow"></the-flow>
    <contact-steps></contact-steps>
    <the-contact></the-contact>
    <!-- <the-service id="service"></the-service> -->
    <!-- <optional-services id="optional"></optional-services> -->
    <news-section id="news"></news-section>
    <the-review id="review"></the-review>
    <question-answer id="faq"></question-answer>
    <template-slider id="template"></template-slider>
    <contact-with-form></contact-with-form>
</template>
<script>

import mv from '../components/MainVisual.vue'
import aboutUs from '../components/AboutUs.vue'
import consult from '../components/Consult.vue'
import contact from '../components/Contact.vue'
import contactWithForm from '../components/ContactWithForm.vue'
// import service from '../components/Service.vue'
import reason  from '../components/Reason.vue'
import flow from '../components/Flow.vue'
// import optionalServices from '../components/OptionalServices.vue'
import questionAnswer from '../components/QuestionAnswer.vue'
import review from '../components/Review.vue'
// import template from '../components/Template.vue'
import contactSteps from '../components/ContactSteps.vue'
import solution from '../components/Solutions.vue'
import templateSlider from '../components/TemplateSlider.vue'
import newsSection from '../components/News.vue'

export default {
  name: 'the-body',
  data() {
    return {
      
    }
  },
  components: {
    'main-visual': mv,
    'about-us': aboutUs,
    'the-consult': consult,
    'the-contact': contact,
    // 'the-service': service,
    'the-reason': reason,
    'the-flow': flow,
    // 'optional-services': optionalServices,
    'question-answer': questionAnswer,
    'the-review': review,
    // 'the-template': template,
    'contact-steps': contactSteps,
    'solution': solution,
    'contact-with-form': contactWithForm,
    'template-slider': templateSlider,
    'news-section': newsSection
  },
  mounted() {
  },
  beforeUnmount() {
    window.dataLayer.push({ 'event': 'pageView' });
  }
}
</script>