<template>
    <div class="contact-steps-container">
        <p class="steps-heading sub-title">まずは簡単３ステップ</p>
        <div class="contact-steps">
            <p>お問合せ又はLINEを追加</p>
            <div class="contact-steps-arrow light-orange"></div>
            <p>ZOOMでのご打ち合わせの予約</p>
            <div class="contact-steps-arrow dark-orange"></div>
            <p>ZOOMミーティングでご要望ヒアリング</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'contact-steps',
    data() {
        return {

        }
    },
    components: {

    }
}
</script>