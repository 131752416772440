<template>
    <div class="templates-slider" :style="{ backgroundImage: 'url(' + slide01 + ')' }"></div>
</template>

<script>
import slide01 from '../assets/slide02.jpg';

export default {
    name: 'template-slider',
    data() {
        return {
            slide01,
        }
    },
}

</script>