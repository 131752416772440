<template>
    <div class="news-container">
        <p class="sub-title">メディア実績</p>
        <div class="news-slider" :style="{ backgroundImage: 'url('+image+')'}"></div>
    </div>
</template>

<script>

import image from '../assets/news.jpg'

export default {
    name: 'NewsSection',
    data() {
        return {
            image
        }
    }
}
</script>

<style>
/* Add your styles here */
</style>
