<template>
    <form class="the-form" @submit.prevent="submitForm">
        <div class="form-group required animation-container">
            <label for="name">会社名</label>
            <input type="text" class="form-control" id="name" v-model="form.name">
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required animation-container">
            <label for="person">担当者様</label>
            <input type="text" class="form-control" id="person" v-model="form.person">
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required animation-container">
            <label for="phone">電話番号</label>
            <input type="tel" class="form-control" id="phone" v-model="form.phone">
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required animation-container">
            <label for="email">メールアドレス</label>
            <input type="email" class="form-control" id="email" v-model="form.email">
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required form-dropdown-selector animation-container">
            <label for="reason">お問い合わせ内容</label>
            <select class="form-control" id="reason" v-model="form.reason">
                <option></option>
                <option>WEBサイト制作のご相談</option>
                <option>HP修正依頼※月１度迄</option>
                <option>HPのエラーについて</option>
                <option>ご料金確認</option>
                <option>オプションの追加</option>
                <option>業務提携</option>
                <option>解約</option>
                <option>その他</option>
            </select>
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required animation-container" :class="form.reason == '解約' ? 'shown' : 'hidden'">
            <label for="cancelReason">解約理由</label>
            <select class="form-control" id="cancelReason" v-model="form.cancelReason">
                <option></option>
                <option>料金が高い</option>
                <option>デザインが気に入らない</option>
                <option>閉業する事になった</option>
                <option>他のサービスを見つけた</option>
                <option>その他理由</option>
            </select>
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group required animation-container"
            :class="form.reason == '解約' && form.cancelReason == 'その他理由' ? 'shown' : 'hidden'">
            <label for="cancelMessage">その他の場合を<br>ご記入下さい</label>
            <textarea class="form-control" id="cancelMessage" rows="3"></textarea>
            <p class="error-indicator">!</p>
        </div>
        <div class="form-group animation-container">
            <label for="message">メッセージ</label>
            <textarea class="form-control" id="message" rows="3"></textarea>
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="privacy_policy_form" v-model="form.privacyPolicy">
            <a @click="formCheckPrivacy()" class="form-check-label" id="privacy_form_label" for="privacy-policy">プライバシーポリシーに同意する</a>
            <p class="error-indicator">!</p>
        </div>
        <privacy-policy class="form-privacy" id="form_privacy_policy"></privacy-policy>
        <button type="submit" class="submit-button">送信</button>
    </form>
</template>

<script>
import privacyPolicy from '../views/Privacy.vue'

export default {
    name: 'TheForm',
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                person: '',
                reason: '',
                message: '',
                cancelReason: '',
                cancelMessage: '',
                privacyPolicy: false
            },
            errorMessages: [
                '会社名を入力してください',
                '担当者様を入力してください',
                '電話番号を入力してください',
                '電話番号を確認してください',
                'メールアドレスを入力してください',
                'メールアドレスを確認してください',
                'お問い合わせ内容を選択してください',
                '解約理由を選択してください',
                'その他の場合をご記入下さい',
                'プライバシーポリシーに同意してください'
            ],
            errors: [
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
            ],
            errorIndicators: [],
            formSent: false
        }
    },
    components: {
        'privacy-policy': privacyPolicy
    },
    methods: {
        submitForm() {
            const nameInput = document.getElementById('name');
            const personInput = document.getElementById('person');
            const phoneInput = document.getElementById('phone');
            const emailInput = document.getElementById('email');
            const reasonInput = document.getElementById('reason');
            const cancelReasonInput = document.getElementById('cancelReason');
            const cancelMessageInput = document.getElementById('cancelMessage');
            const messageInput = document.getElementById('message');
            const privacyPolicyInput = document.getElementById('privacy_policy_form');

            const name = nameInput.value;
            const person = personInput.value;
            const phone = phoneInput.value;
            const email = emailInput.value;
            const reason = reasonInput.value;
            const cancelReason = cancelReasonInput.value;
            const cancelMessage = cancelMessageInput.value;
            const message = messageInput.value;
            const self = this;

            nameInput.classList.add("error");
            personInput.classList.add("error");
            phoneInput.classList.add("error");
            emailInput.classList.add("error");
            reasonInput.classList.add("error");
            cancelReasonInput.classList.add("error");
            cancelMessageInput.classList.add("error");
            privacyPolicyInput.classList.add("error");

            var hasError = true;

            var payload = {
                name: name,
                person: person,
                phone: phone,
                email: email,
                reason: reason,
                cancelReason: cancelReason,
                cancelMessage: cancelMessage,
                message: message
            };

            this.errorIndicators = [];

            if (name === '') {
                this.errors[0] = true;
                this.errorIndicators.push(this.errorMessages[0]);
            }
            else {
                this.errors[0] = false;
                nameInput.classList.remove("error");
            }

            if (person === '') {
                this.errors[1] = true;
                this.errorIndicators.push(this.errorMessages[1]);
            }
            else {
                this.errors[1] = false;
                personInput.classList.remove("error");
            }

            if (phone === '') {
                this.errors[2] = true;
                this.errorIndicators.push(this.errorMessages[2]);
            }
            else if (!phone.match(/^[0-9]{11}$/)) {
                this.errors[2] = true;
                this.errorIndicators.push(this.errorMessages[3]);
            }
            else {
                this.errors[2] = false;
                phoneInput.classList.remove("error");
            }

            if (email === '') {
                this.errors[3] = true;
                this.errorIndicators.push(this.errorMessages[4]);
            }
            else if (!email.match(/^[A-Za-z0-9]+[\w.-]+@[\w.-]+\.\w{2,}$/)) {
                this.errors[3] = true;
                this.errorIndicators.push(this.errorMessages[5]);
            }
            else {
                this.errors[3] = false;
                emailInput.classList.remove("error");
            }

            if (reason === '') {
                this.errors[4] = true;
                this.errorIndicators.push(this.errorMessages[6]);
            }
            else {
                this.errors[4] = false;
                reasonInput.classList.remove("error");
            }

            if (reason === '解約' && cancelReason === '') {
                this.errors[5] = true;
                this.errorIndicators.push(this.errorMessages[7]);
            }
            else {
                this.errors[5] = false;
                cancelReasonInput.classList.remove("error");
            }

            if (cancelReason === 'その他理由' && reason === '解約' && cancelMessage === '') {
                this.errors[6] = true;
                this.errorIndicators.push(this.errorMessages[8]);
            }
            else {
                this.errors[6] = false;
                cancelMessageInput.classList.remove("error");
            }

            if (this.form.privacyPolicy === false) {
                this.errors[7] = true;
                this.errorIndicators.push(this.errorMessages[9]);
            }
            else {
                this.errors[7] = false;
            }

            if (this.errors.includes(true)) {
                hasError = true;
            }
            else {
                hasError = false;
            }
            

            if (hasError) {
                alert(this.errorIndicators.join('\n'));
                return;
            }
            else {
                if (reason === '解約') {
                    this.$store.commit('setCancelFormToTrue');
                    // eslint-disable-next-line no-undef
                    $.ajax({
                        type: "POST",
                        url: "/sendcancelmail.php",
                        data: {
                            mail: payload.email,
                        },// passing the values
                        success: function (msg) {
                            console.log(msg)
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            console.log(XMLHttpRequest);
                            console.log(textStatus);
                            console.log(errorThrown);
                        }
                    });
                }
                else {
                    this.$store.commit('setCancelFormToFalse');
                    window.dataLayer.push({ 'event': 'custom_form_submit' });
                    // eslint-disable-next-line no-undef
                    $.ajax({
                        type: "POST",
                        url: "/sendmail.php",
                        data: {
                            mail: payload.email,
                        },// passing the values
                        success: function (msg) {
                            console.log(msg)
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            console.log(XMLHttpRequest);
                            console.log(textStatus);
                            console.log(errorThrown);
                        }
                    });
                }


                // eslint-disable-next-line no-undef
                $.ajax({
                    type: "POST",
                    url: "/sendform.php",
                    data: {
                        name: payload.name,
                        person: payload.person,
                        mail: payload.email,
                        reason: payload.reason,
                        phone_number: payload.phone,
                        cancelReason: payload.cancelReason,
                        cancelMessage: payload.cancelMessage,
                        message: payload.message
                    },// passing the values
                    success: function (msg) {
                        console.log(msg)
                        self.$router.push({ name: 'thanks-form' });
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log(XMLHttpRequest);
                        console.log(textStatus);
                        console.log(errorThrown);
                    }
                });

                // this.$router.push({ name: 'thanks-form' });
            }
        },
        formCheckPrivacy() {
            const privacyPolicyInput = document.getElementById('form_privacy_policy');
            const privacyPolicyLabel = document.getElementById('privacy_form_label');
            privacyPolicyInput.classList.toggle("open");
            privacyPolicyLabel.classList.toggle("open");
        }
    },
}
</script>
