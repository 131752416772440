<template>
    <div class="consult-container">
        <div class="consult-title animation-container">
            <h2>シングルページアプリケーション</h2>
            <p>SPA</p>
            <h2>とは?</h2>
        </div>
        <p class="animation-container">WEB作成での最新のテクノロジーです。<br class="br-sp">ページ遷移を行わないで、情報、コンテンツの<br class="br-sp">切り替えを動的に行えるので、<br
                class="br-sp">ユーザービリティが上がりやすく<br class="br-pc">
                <br class="br-sp">遷移時間が『０秒』の為ユーザーのストレスを<br class="br-sp">極限まで軽減できます。シングルページ<br class="br-sp">アプリケーションの代表例は、<br class="br-sp">Gmail、Facebookです。</p>
        <div class="consult-content-container closed" @click="handleToggle($event)">
            <h4 class="consult-sub-title animation-container">
                <span>SPA</span>のメリット
                <div class="open-mark">
                    <div class="line-vertical"></div>
                    <div class="line-horizontal"></div>
                </div>
            </h4>
            <div class="consult-contents">
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>優れたUI/UXを実現可能</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            優れたUI/UXを実現するために、SPAは最適な選択
                            肢です。現代では、UI/UXがアプリケーションや
                            サービスの成功に大きな影響を与え、市場の要求に
                            合わせた素早い改善が必要です。SPAを採用する
                            ことで、優れたUI/UXを提供し、フレームワークを
                            活用して迅速な開発が可能となります。
                        </p>
                    </div>
                </div>
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>ネイティブアプリの<br>代替として利用可能</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            SPAを採用することで、ネイティブアプリに特有の
                            機能、例えばホーム画面からの起動、
                            オフラインでのページ閲覧、そしてプッシュ
                            通知などを実現できます。ネイティブアプリの
                            開発にはストアの審査などが関わるため、多くの
                            企業がSPAを利用してWebアプリを開発する傾向が
                            高まっています。
                        </p>
                    </div>
                </div>
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>高速なページ遷移と高度な<br>操作性の実現が可能</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            SPAを導入することで、従来のウェブページと
                            比べて読み込み速度やページ遷移速度を大幅に向上
                            させることが可能です。インターネットの高速化が
                            進んでいる中で、読み込みの遅延がコンバージョン
                            低下につながることがより顕著になっています。
                            SPAを利用することで、このような問題を効果的に
                            解消できます。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="consult-content-container closed" @click="handleToggle($event)">
            <h4 class="consult-sub-title animation-container">
                <span>SPA</span>のデメリット
                <div class="open-mark">
                    <div class="line-vertical"></div>
                    <div class="line-horizontal"></div>
                </div>
            </h4>
            <div class="consult-contents">
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>開発コストが高くなる</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            SPAを開発する際には、通常のウェブサイトで
                            提供される「進む」「戻る」「リロード」などの
                            機能を再実装する必要があります。自然なこと
                            ですが、新たな機能を開発するにつれて、開発
                            コストも増加します。
                        </p>
                    </div>
                </div>
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>スキルを持つエンジニアが<br>限られている</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            SPAは比較的新しい技術であり、SPAに特化した
                            スキルを持つエンジニアの数が限られています。
                            国内のIT人材が不足している状況で、SPAの
                            専門知識を持つ優れたエンジニアを採用することは
                            課題となることがあります。
                        </p>
                    </div>
                </div>
                <div class="consult-content animation-container">
                    <div class="consult-header">
                        <h5>SEOへの影響が懸念される</h5>
                    </div>
                    <div class="consult-body">
                        <p>
                            SPAを利用したウェブサイトは、一般的に検索
                            エンジンのクローラーがJavaScriptを実行しない
                            ため、SEOの観点から不利になる可能性があると
                            指摘されています。従って、SEOを重要視する
                            場合、SPAの採用には注意深く知識が必要です。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <the-points id="point"></the-points>
    </div>
</template>
<script>

import thePoint from '../components/Points.vue'

export default {
    name: 'the-consult',
    components: {
        'the-points': thePoint
    },
    methods: {
        handleToggle(e) {
            const target = e.currentTarget;
            target.classList.toggle('closed');
        }
    },
}
</script>