<template>
    <div class="review-container">
        <div class="background-title">
            <h2 class="animation-container">お客様の声をご覧ください</h2>
            <img id="title_background02" :src="titleBackground" alt="title-background">
        </div>
        <div class="review-boxes">
            <div class="review-box animation-container">
                <div class="review-header">
                    <img :src="reviewPic01" alt="review-pic01">
                    <div class="review-header-rate">
                        <div class="review-rate">
                            <img v-for="item in 5" :key="item" :src="reviewStar" alt="review-star">
                        </div>
                        <p class="review-name">日本ドローンスクール<span>様</span></p>
                    </div>
                </div>
                <div class="review-body">
                    <p class="review-title">最高のコストパフォーマンスと<br class="br-pc">結果が素晴らしい</p>
                    <p>50万円で以前LP制作しましたがで成果が<br class="br-pc">全然でませんでした。
                        ヒートマックを<br class="br-pc">導入したところユーザー離脱も多い原因が<br class="br-pc">発覚。
                        成果がでなくて悩んでいたところ、<br class="br-pc">SPAlab様を見つけ制作依頼をしたとろこ<br class="br-pc">ユーザー定着率やCVもかなりUPし入校者が<br class="br-pc">増え売上も上がり満足しております。
                    </p>
                    <a href="https://japandroneschool.com/" target="_blank">ホームページを見る</a>
                </div>
            </div>
            <div class="review-box animation-container">
                <div class="review-header">
                    <img :src="reviewPic02" alt="review-pic01">
                    <div class="review-header-rate">
                        <div class="review-rate">
                            <img v-for="item in 5" :key="item" :src="reviewStar" alt="review-star">
                        </div>
                        <p class="review-name">株式会社Correct<span>様</span></p>
                    </div>
                </div>
                <div class="review-body">
                    <p class="review-title">素晴らしい集客効果<br class="br-pc"><span> - </span></p>
                    <p>新しいホームページが完成し、<br class="br-pc">「かっこいいなー！」と社員たちが<br class="br-pc">大喜びしていました。
                        美しい写真と当社の<br class="br-pc">実績は素晴らしくまとめられ、取扱店舗<br class="br-pc">一覧もわかりやすく整理されています。
                        <br class="br-pc">
                        ホームページをリニューアルして数日後、<br class="br-pc">驚くことに問い合わせが早速入って<br class="br-pc">きました。
                    </p>
                    <a href="https://correct1991.com/" target="_blank">ホームページを見る</a>
                </div>
            </div>
            <div class="review-box animation-container">
                <div class="review-header">
                    <img :src="reviewPic03" alt="review-pic01">
                    <div class="review-header-rate">
                        <div class="review-rate">
                            <img v-for="item in 5" :key="item" :src="reviewStar" alt="review-star">
                        </div>
                        <p class="review-name">和食 なかもり<span>様</span></p>
                    </div>
                </div>
                <div class="review-body">
                    <p class="review-title">価格、対応、スピード<br class="br-pc">クオリティに大満足</p>
                    <p>オープンまでの時間がない中迅速に<br class="br-pc">対応して頂けました。
                        制作の依頼をした<br class="br-pc">ところ、WEBの知識がない私にも分かり<br class="br-pc">やすく、ベストな形をご提案頂き、<br class="br-pc">素晴らしいデザインと完成までのスピードが<br class="br-pc">早く大変助かりました。また手の届きやすい<br class="br-pc">価格帯で大満足です。
                    </p>
                    <a href="https://washoku-nakamori.com/" target="_blank">ホームページを見る</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import titleBackground from '../assets/title-background-review.jpg'
import reviewPic01 from '../assets/review-pic01.png'
import reviewPic02 from '../assets/review-pic02.png'
import reviewPic03 from '../assets/review-pic03.png'
import reviewStar from '../assets/review-star.png'


export default {
    name: 'the-review',
    components: {

    },
    data() {
        return {
            titleBackground: titleBackground,
            reviewPic01: reviewPic01,
            reviewPic02: reviewPic02,
            reviewPic03: reviewPic03,
            reviewStar: reviewStar,
        }
    },
}
</script>