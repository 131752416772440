<template>
    <div class="mv-container contents-container">
        <div class="mv-left">
            <h2 class="mv-text">
                <span class="mv-first">知らなかったは最大の損失</span>
                <span class="mv-second">ホームページ制作で成功の一歩先へ</span>
                <span class="mv-third">7980円で最先端技術<span class="hidden-text-trigger">SPA</span>業界最安水準で実現</span><span class="hidden-text">（シングルページアプリケーション）</span>
            </h2>
            <router-link to="/contact" class="contact-btn pc-version">お問い合わせはこちら</router-link>
        </div>
        <mv-pc></mv-pc>
        <router-link to="/contact" class="contact-btn mobile-version">お問い合わせはこちら</router-link>
    </div>
</template>
<script>

import mvPic from '../assets/mv.png'
import mvBack from '../assets/mv-back.png'
import mvPc from './mvPc.vue'

export default {
    name: 'main-visual',
    data() {
        return {
            mvPic: mvPic,
            mvBack: mvBack
        }
    },
    components: {
        'mv-pc': mvPc
    },
    mounted() {
        const hiddenTextTrigger = document.getElementsByClassName("hidden-text-trigger")[0];
        hiddenTextTrigger.addEventListener('mouseover', this.handleMouseOver);
        hiddenTextTrigger.addEventListener('mouseleave', this.handleMouseLeave);
    },
    // unmounted() {
    //     const the_container = document.getElementsByClassName("container")[0];
    //     the_container.removeEventListener('scroll', this.handleScroll);
    // },
    methods:{
        handleMouseOver() {
            const hiddenText = document.getElementsByClassName("hidden-text")[0];
            if(!hiddenText.classList.contains("hidden-text-active")) {
                hiddenText.classList.add("hidden-text-active");
            }
        },
        handleMouseLeave() {
            const hiddenText = document.getElementsByClassName("hidden-text")[0];
            if(hiddenText.classList.contains("hidden-text-active")) {
                hiddenText.classList.remove("hidden-text-active");
            }
        }
    }
}
</script>