<template>
    <div class="header-container">
        <router-link to="/" class="logo-container">
            <img :src="logo" alt="ロゴ">
            <h1 style="opacity: 0; position: absolute;">SPAlab</h1>
        </router-link>
        <div class="header-contact">
            <div class="header-contact-phone">
                <img :src="phone" alt="電話">
                <a href="tel:0344007957" class="the-phone">03-4400-7957</a>
            </div>
            <div class="header-contact-time">
                <p>受付時間 11:00~19:00受付可能</p>
            </div>
        </div>
        <div class="ui-buttons">
            <a href="tel:0344007957" class="ui-button the-phone"><img :src="phoneWhite"></a>
            <router-link to="/contact" class="ui-button"><img :src="emailIcon"></router-link>
        </div>
    </div>
</template>
<script>
import logo from '../assets/logo.png'
import phone from '../assets/phone.png'
import phoneWhite from '../assets/phone-white.png'
import emailIcon from '../assets/email-icon.png'

export default {
    name: 'the-header',
    data() {
        return {
            logo: logo,
            phone: phone,
            phoneWhite: phoneWhite,
            emailIcon: emailIcon,
        }
    },
    components: {

    }
}
</script>