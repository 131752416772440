<template>
    <div class="points-container">
        <h4 class="consult-sub-title animation-container">
            しかし<span>SPAlab</span>なら
        </h4>
        <div class="points">
            <div class="point animation-container">
                <div class="point-title">
                    <img :src="point01" alt="ポイント01">
                    <h3>開発コストダウン</h3>
                </div>
                <p>豊富なSPA仕様のテンプレートと専門知識の<br class="br-pc">
                    ある<br class="br-sp">技術者が常駐し運営していますので、<br class="br-pc">
                    大幅なコストカットが可能になりました。<br class="br-pc">
                    SPAでありながら<br class="br-sp">低価格を実現!!
                </p>
            </div>
            <div class="point animation-container">
                <div class="point-title">
                    <img :src="point02" alt="ポイント02">
                    <h3>SPAに精通した<br class="br-pc">自社<br class="br-sp">エンジニア</h3>
                </div>
                <p>日本では希少なSPAに精通したエンジニアが<br class="br-pc">
                    弊社に所属している為、低価格・高品質なSPA<br class="br-pc">HPの<br class="br-sp">提供を可能にしております。
                    <br>
                    <br>
                    お客様の要望を是非聞かせて下さい。<br class="br-pc">
                    <br class="br-sp">弊社のエンジニアが実現のお手伝いを<br class="br-sp"><br class="br-pc">
                    させていただきます！
                </p>
            </div>
            <div class="point animation-container">
                <div class="point-title">
                    <img :src="point03" alt="ポイント03">
                    <h3>SPA専用の<br class="br-pc">SEO対策</h3>
                </div>
                <p>一般的にSPAはJavaScriptを実行しない為、<br class="br-pc"><br class="br-sp">
                    SEO対策の観点では脆弱であると知られて<br class="br-pc">
                    おり、<br class="br-sp">一番のデメリットで有ります。<br class="br-pc">
                    しかし、SPAの<br class="br-sp">特性に合わせたSEO対策方法が<br class="br-pc">
                    あり、SPAlabでは<br class="br-sp">実装していますので、<br class="br-pc">
                    SEOの懸念も払拭<br class="br-sp">できています。
                </p>
            </div>
        </div>
    </div>
</template>
<script>

import point01 from '../assets/point01.png'
import point02 from '../assets/point02.png'
import point03 from '../assets/point03.png'

export default {
    name: 'the-points',
    components: {

    },
    data() {
        return {
            point01: point01,
            point02: point02,
            point03: point03
        }
    },
}
</script>