<template>
    <div class="footer-container">
        <div class="footer-upper animation-container">
            <div class="footer-upper-left">
                <div class="footer-logo">
                    <img src="../assets/logo.png" alt="ロゴ">
                </div>
                <div class="footer-contact">
                    <a href="tel:0344007957" class="the-phone">03-4400-7957</a>
                    <p>受付時間 11:00～19:00（土日祝除く）</p>
                </div>
            </div>
            <div class="footer-upper-right">
                <a href="/#top">・トップ</a>
                <a href="/#point">・3つのポイント</a>
                <a href="/#about-spa">・SPAとは?</a>
                <a href="/#plan">・プラン一覧</a>
                <a href="/#flow">・作成の流れ</a>
                <a href="/#review">・お客様の声</a>
                <a href="/#faq">・よくある質問</a>
                <a href="/#optional">・オプションサービス</a>
                <router-link to="/contact">・お問い合わせフォーム</router-link>
            </div>
        </div>
        <div class="footer-bottom animation-container">
            <p>Copyright ©Artytech. All Rights Reserved.</p>
            <div class="footer-outer-links">
                <a href="https://artytech.jp/" target="_blank">運営会社</a>
                <router-link to="/privacy">プライバシーポリシー</router-link>
                <router-link to="/security">特定証取時期法に基づく表記</router-link>
                <router-link to="/notation">利用規約</router-link>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'the-footer',
    components: {

    }
}
</script>