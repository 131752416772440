<template>
  <div id="app">
    <template v-if="isSmartPhone">
      <the-header />
      <router-view />
      <the-footer />
    </template>
    <div ref="ui_buttons" class="ui-buttons hidden">
      <a @click="handleTopBtn" class="ui-button"><img :src="arrowUp"></a>
      <a href="tel:0344007957" class="ui-button the-phone"><img :src="phoneWhite"></a>
      <router-link to="/contact" class="ui-button"><img :src="emailIcon"></router-link>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

import arrowUp from './assets/arrow-up.png'
import phoneWhite from './assets/phone-white.png'
import emailIcon from './assets/email-icon.png'

export default {
  name: 'App',
  data() {
    return {
      weight: 0.08,
      arrowUp: arrowUp,
      phoneWhite: phoneWhite,
      emailIcon: emailIcon,
      currentScroll: 0,
      isSmartPhone: true
    }
  },
  components: {
    'the-header': Header,
    'the-footer': Footer
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll);
    })
    // this.$store.dispatch('getMetaData')
  },
  created() {
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleTopBtn() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll() {
      const artytech_moving = document.getElementById("artytech_moving");
      
      if (window.scrollY > 100) {
        this.$refs.ui_buttons.classList.remove("hidden");
      } else {
        this.$refs.ui_buttons.classList.add("hidden");
      }
      if (this.$route.path == '/') {
        if (this.currentScroll < window.scrollY) {
          artytech_moving.style.transform = `translateY(-20px)`;

        } else {
          artytech_moving.style.transform = `translateY(20px)`;

        }
        setTimeout(() => {
          artytech_moving.style.transform = `translateY(${0}px)`;

        }, 300);

        this.currentScroll = window.scrollY;
      }
    }
  },

}
</script>

<style></style>
