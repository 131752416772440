<template>
    <div class="flow-container">
        <div class="background-title">
            <h2 class="animation-container">ホームページ作成の流れ</h2>
            <img id="title_background02" :src="titleBackground" alt="title-background">
        </div>
        <p class="animation-container">ご契約後のフローは下記の通りです。
不明点、ご質問がございましたら、お気軽にお問い合わせください。
        </p>
        <div id="flow_items" class="flow-items animation-container">
            <div id="flow_title" class="flow-title">
                <img :src="currentFlowIcon">
                <p class="list-number">{{ currentFlowIndex }}</p>
                <p class="list-title">{{ currentFlowTitle }}</p>
                <div class="flow-controller">
                    <div id="arrow_down" class="arrow-down">
                        <img :src="arrowDown" alt="arrow-down">
                    </div>
                    <div id="arrow_up" class="arrow-up">
                        <img :src="arrowUp" alt="arrow-up">
                    </div>
                </div>
            </div>
            <div id="flow_list" class="flow-list">
                <div class="flow-text" v-for="(item, index) in flowTexts" :key="index">
                    <p v-html="item" :id="index"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import titleBackground from '../assets/title-background-flow.jpg'
import flowIcon01 from '../assets/flow-icon01.png'
import flowIcon03 from '../assets/service-icon-05.png'
import flowIcon04 from '../assets/flow-icon04.png'
import flowIcon05 from '../assets/service-icon-01.png'
import flowIcon06 from '../assets/point01.png'
import flowIcon07 from '../assets/flow-icon07.png'
import flowIcon08 from '../assets/flow-icon08.png'
import flowIcon09 from '../assets/flow-icon09.png'
import flowIcon10 from '../assets/flow-icon10.png'
import arrowDown from '../assets/arrow-down.png'
import arrowUp from '../assets/arrow-up.png'

export default {
    name: 'the-flow',
    components: {

    },
    data() {
        return {
            titleBackground: titleBackground,
            flowTexts: [
                'ウェブページの目的、対象読者、主要な機能を定義します。<br>コンテンツとレイアウトの大まかなアウトラインを作成します。',
                'ウェブページに必要なテキスト、画像、<br>動画などのメディアコンテンツを作成します。<br>目標やメッセージに合致するものにします。',
                'ブランドアイデンティティに合った視覚的なデザインを作成します。<br>カラー、フォント、スタイルを選び、<br>ユーザーエクスペリエンスと可読性を向上させます。',
                'HTML、CSS、JavaScriptコードを書いてウェブページの構造と<br>スタイルを実装します。さまざまな画面サイズに<br>対応するレスポンシブデザインを確保します。',
                'JavaScriptやフロントエンドライブラリを使って、対話型要素、<br>フォーム、ボタン、ナビゲーションメニューを実装します。',
                'ウェブページの機能、レスポンシブ性、異なる<br>ブラウザやデバイスでの互換性を徹底的にテストします。',
                '画像、コード、アセットを最適化してウェブページの<br>読み込み速度を向上させます。<br>スムーズなユーザーエクスペリエンスを確保します。',
                'メタタグ、キーワード、説明文などのSEOテクニックを<br>統合します。アナリティクスツールを設定してユーザーの<br>行動やトラフィックを追跡します。',
                'ウェブページをホスティングサーバーにデプロイします。<br>パフォーマンスをモニタリングし、ユーザーフィードバックを<br>収集し、必要に応じて更新を行い、ウェブページを<br>最新かつ効果的な状態に保ちます。'
            ],
            flowTitles: [
                '計画',
                'コンテンツ作成',
                'デザイン',
                'フロントエンド開発',
                '機能実装',
                'テスト',
                '最適化',
                'SEOとアナリティクス',
                '公開とメンテナンス'
            ],
            flowIcons: [
                flowIcon01,
                flowIcon03,
                flowIcon04,
                flowIcon05,
                flowIcon06,
                flowIcon07,
                flowIcon08,
                flowIcon09,
                flowIcon10
            ],
            flowIndex: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09'
            ],
            currentFlowIcon: flowIcon01,
            currentFlowIndex: '01',
            currentFlowTitle: '計画',
            currentIndex: 0,
            arrowDown: arrowDown,
            arrowUp: arrowUp,
        }
    },
    mounted() {
        const self = this;
        const flowTexts = document.querySelectorAll('.flow-text');
        const flowItems = document.getElementById('flow_items');
        const arrowUp = document.getElementById('arrow_up');
        const arrowDown = document.getElementById('arrow_down');
        let scrollIndex = 0;
        // let currentScrollPos = 0;

        // only for pc define by screen size
        // if (window.innerWidth > 1024) {
        //     flowItems.addEventListener('scroll', handleScroll);
        // }

        arrowUp.classList.add('inactive');
        arrowUp.addEventListener('click', () => {
            if (scrollIndex > 0) {
                scrollIndex--;
                setFlowClasses(scrollIndex);
                flowItems.scrollTop = flowTexts[scrollIndex].offsetTop + flowTexts[scrollIndex].offsetHeight / 2 - flowItems.offsetHeight / 2;
                if (arrowDown.classList.contains('inactive')) {
                    arrowDown.classList.remove('inactive')
                }
                if (scrollIndex == 0) {
                    if (!arrowUp.classList.contains('inactive')) {
                        arrowUp.classList.add('inactive')
                    }
                }
            }
        });
        arrowDown.addEventListener('click', () => {
            if (scrollIndex < flowTexts.length - 1) {
                scrollIndex++;
                setFlowClasses(scrollIndex);
                flowItems.scrollTop = flowTexts[scrollIndex].offsetTop + flowTexts[scrollIndex].offsetHeight / 2 - flowItems.offsetHeight / 2;
                if (arrowUp.classList.contains('inactive')) {
                    arrowUp.classList.remove('inactive')
                }
                if (scrollIndex == (flowTexts.length - 1)) {
                    if (!arrowDown.classList.contains('inactive')) {
                        arrowDown.classList.add('inactive')
                    }
                }
            }
        });

        // function handleScroll() {

        //     let newScrollPos = flowItems.scrollTop;
        //     let scrollDirection = newScrollPos > currentScrollPos ? 'down' : 'up';
        //     console.log(scrollIndex)
        //     console.log(flowTexts.length - 1)

        //     if (newScrollPos % 8 === 0) {
        //         if (scrollDirection === 'down') {
        //             if (scrollIndex < flowTexts.length - 1) {
        //                 scrollIndex++;
        //                 setFlowClasses(scrollIndex);
        //                 if(arrowUp.classList.contains('inactive')){
        //                     arrowUp.classList.remove('inactive')
        //                 }
        //             }
        //             else if(scrollIndex == flowTexts.length){
        //                 if(!arrowDown.classList.contains('inactive')){
        //                     arrowDown.classList.add('inactive')
        //                 }
        //             }
        //         } else {
        //             if (scrollIndex > 0) {
        //                 scrollIndex--;
        //                 setFlowClasses(scrollIndex);
        //                 if(arrowDown.classList.contains('inactive')){
        //                     arrowDown.classList.remove('inactive')
        //                 }
        //             }
        //             else{
        //                 if(!arrowUp.classList.contains('inactive')){
        //                     arrowUp.classList.add('inactive')
        //                 }
        //             }
        //         }
        //         handleFlowEvents();
        //     }
        // }

        // function handleFlowEvents() {
        //     flowItems.scrollTop = flowTexts[scrollIndex].offsetTop + flowTexts[scrollIndex].offsetHeight / 2 - flowItems.offsetHeight / 2;
        //     currentScrollPos = flowItems.scrollTop;
        //     flowItems.removeEventListener('scroll', handleScroll);
        //     flowItems.style.overflow = 'hidden';
        //     setTimeout(() => {
        //         flowItems.addEventListener('scroll', handleScroll);
        //         flowItems.style.overflow = 'auto';
        //     }, 50);
        // }

        flowTexts.forEach((i) => {
            i.classList.add('hidden');
        });
        flowTexts[0].classList.add('active');
        flowTexts[0].classList.remove('hidden');
        flowTexts[1].classList.add('half-opacity');
        flowTexts[1].classList.remove('hidden');
        flowTexts[2].classList.add('quarter-opacity');
        flowTexts[2].classList.remove('hidden');

        function setFlowClasses(index) {
            let id = parseInt(index);
            let idNext = id + 1;
            let idPrev = id - 1;
            let idNextNext = id + 2;
            let idPrevPrev = id - 2;
            let idNextHidden = id + 3;
            let idPrevHidden = id - 3;
            if (idNext < flowTexts.length) {
                flowTexts[idNext].classList.add('half-opacity');
                flowTexts[idNext].classList.remove('hidden');
                flowTexts[idNext].classList.remove('quarter-opacity');
                flowTexts[idNext].classList.remove('active');
            }
            if (idPrev >= 0) {
                flowTexts[idPrev].classList.add('half-opacity');
                flowTexts[idPrev].classList.remove('hidden');
                flowTexts[idPrev].classList.remove('quarter-opacity');
                flowTexts[idPrev].classList.remove('active');
            }
            if (idNextNext < flowTexts.length) {
                flowTexts[idNextNext].classList.add('quarter-opacity');
                flowTexts[idNextNext].classList.remove('hidden');
                flowTexts[idNextNext].classList.remove('half-opacity');
                flowTexts[idNextNext].classList.remove('active');
            }
            if (idPrevPrev >= 0) {
                flowTexts[idPrevPrev].classList.add('quarter-opacity');
                flowTexts[idPrevPrev].classList.remove('hidden');
                flowTexts[idPrevPrev].classList.remove('half-opacity');
                flowTexts[idPrevPrev].classList.remove('active');
            }
            if (idNextHidden < flowTexts.length) {
                flowTexts[idNextHidden].classList.add('hidden');
                flowTexts[idNextHidden].classList.remove('quarter-opacity');
                flowTexts[idNextHidden].classList.remove('half-opacity');
                flowTexts[idNextHidden].classList.remove('active');
            }
            if (idPrevHidden >= 0) {
                flowTexts[idPrevHidden].classList.add('hidden');
                flowTexts[idPrevHidden].classList.remove('quarter-opacity');
                flowTexts[idPrevHidden].classList.remove('half-opacity');
                flowTexts[idPrevHidden].classList.remove('active');
            }
            flowTexts[id].classList.add('active');
            flowTexts[id].classList.remove('hidden');
            flowTexts[id].classList.remove('half-opacity');
            flowTexts[id].classList.remove('quarter-opacity');
            self.currentFlowIcon = self.flowIcons[id];
            self.currentFlowIndex = self.flowIndex[id];
            self.currentFlowTitle = self.flowTitles[id];
        }

        // let observerOptions = {
        //     rootMargin: '-300px',
        //     threshold: 1
        // }

        // var observer = new IntersectionObserver(observerCallback, observerOptions);

        // function observerCallback(entries) {
        //     entries.forEach(entry => {
        //         if (entry.isIntersecting) {
        //             const visibleRatio = entry.intersectionRatio;
        //             if (visibleRatio >= 0.3) {
        //                 let index = entry.target.querySelectorAll('p')[0].id;
        //                 self.currentFlowIcon = self.flowIcons[parseInt(index)];
        //                 self.currentFlowIndex = self.flowIndex[parseInt(index)];
        //                 self.currentFlowTitle = self.flowTitles[parseInt(index)];
        //                 setFlowClasses(index);
        //             }
        //         }
        //     });
        // }

        // let target = '.flow-text';
        // document.querySelectorAll(target).forEach((i) => {
        //     if (i) {
        //         observer.observe(i);
        //     }
        // });

        // Observer Controller JS --------------- end
    },
}
</script>